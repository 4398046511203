<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="w-100 cart">
		<div :class="isSmallDevice ? 'h-80' : 'h-55'" />
		<b-card-group>
			<b-card
				v-if="!loading"
				:class="isSmallDevice ? 'py-5' : 'px-5 py-4'"
				class="border-0 bg-white mb-0"
				style="opacity: 0.9;">
				<div class="agreements-container">
					<div v-if="!isSmallDevice">
						<div
							class="toc-header"
							:style="{
								top: isReplicated ? '160px' : '100px',
							}">
							<h4><b>{{ translate('table_of_contents') }}</b></h4>
						</div>
						<div
							class="toc"
							:style="tocStyle">
							<a
								v-for="section in sections"
								:key="section.id"
								:href="`#${section.id}`"
								class="toc-item"
								:class="{
									'active-section': activeSection === section.id.toString()
								}"
								:style="{
									'padding-left': 15 * section.attributes.level + 'px',
									'font-weight': section.attributes.level === 1 ? 'bolder' : 'normal',
								}"
								@click.prevent="scrollToSection(section.id)"
								v-html="section.attributes.title" />
						</div>
					</div>
					<div
						v-else
						class="toc-small">
						<select
							id="section-item"
							v-model="activeSection"
							name="section-item"
							class="form-control"
							@change="scrollToSection(activeSection)">
							<template>
								<template v-if="sections.length">
									<option
										v-for="section in sections"
										:key="section.id"
										:href="`#${section.id}`"
										:value="section.id"
										v-html="section.attributes.title" />
								</template>
							</template>
						</select>
					</div>
					<div
						class="agreements-content"
						:class="{
							'agreements-content-small' : isSmallDevice,
						}">
						<img
							:src="APP_SYMBOL"
							alt="Velovita Symbol"
							class="background-image"
							:class="{
								'background-image-small' : isSmallDevice,
							}">
						<div
							class="text-center"
							:class="adjustContent ? 'mt-5 pt-2' : ''">
							<h1>
								<b>{{ translate(`${documentCodeName}_title_1`).toUpperCase() }} <b class="text-primary">{{ translate(`${documentCodeName}_title_2`).toUpperCase() }}</b></b>
							</h1>
						</div>
						<div
							v-for="section in sections"
							:id="section.id"
							:key="section.id"
							class="section row-12"
							:style="{
								'padding-left': 15 * (section.attributes.level - 1) + 'px',
							}">
							<p
								:class="'title-' + section.attributes.level"
								v-html="section.attributes.title" />
							<div
								v-if="section.attributes.content !== null"
								class="p-style"
								v-html="section.attributes.content" />
						</div>
						<div
							v-if="version"
							class="text-center text-medium primary-color pb-2">
							{{ translate('version') }} {{ version }}
						</div>
						<div
							class="align-items-center text-center"
							:class="isSmallDevice ? 'pb-5' : ''">
							<button
								type="submit"
								class="btn btn-primary w-100"
								@click="openTerms()">
								{{ translate('download') }}
							</button>
						</div>
					</div>
				</div>
			</b-card>
			<div
				v-else
				class="loading-container">
				<is-loading
					size="xl"
					:loading-label="translate('loading')"
					:loading="loading" />
			</div>
		</b-card-group>
	</div>
</template>

<script>
import WindowSizes from '@/mixins/WindowSizes';
import DistributorInformation from '@/util/DistributorsInformation';
import { DistributorsInformation as messages } from '@/translations';
import { TERMS_AND_CONDITIONS_REDIRECTION, APP_SYMBOL } from '@/settings/General';

export default {
	name: 'OfficialDocument',
	mixins: [WindowSizes],
	messages: [messages],
	data() {
		return {
			distributorInformation: new DistributorInformation(),
			activeSection: null,
			tocStyle: {},
			APP_SYMBOL,
			TERMS_AND_CONDITIONS_REDIRECTION,
			loaded: false,
		};
	},
	computed: {
		sections() {
			try {
				return this.distributorInformation.data.response.data.data;
			} catch (e) {
				return [];
			}
		},
		version() {
			try {
				return this.distributorInformation.data.response.data.meta.version;
			} catch (e) {
				return null;
			}
		},
		documentCodeName() {
			try {
				return this.distributorInformation.data.response.data.meta.code_name;
			} catch (e) {
				return '';
			}
		},
		isSmallDevice() {
			return ['xs', 'sm'].includes(this.windowWidth);
		},
		isReplicated() {
			try {
				return (this.$replicated.replicatedSponsor() !== null);
			} catch (e) {
				return false;
			}
		},
		adjustContent() {
			try {
				return !this.isSmallDevice && this.isReplicated;
			} catch (e) {
				return false;
			}
		},
		loading() {
			return !!this.distributorInformation.data.loading;
		},
		agreementsUrl() {
			try {
				return this.distributorInformation.data.response.data.meta.url;
			} catch (e) {
				return this.TERMS_AND_CONDITIONS_REDIRECTION;
			}
		},
	},
	watch: {
		loaded(value) {
			if (value) {
				try {
					// eslint-disable-next-line default-case
					switch (this.$route.query.section) {
					case 'privacy-policy':
					case 'terms-and-conditions-of-use':
						// eslint-disable-next-line no-case-declarations
						const parentSection = document.getElementById(this.$route.query.section).parentNode;
						this.scrollToSection(parentSection.getAttribute('href').replace('#', ''));
						break;
					}
				} catch (e) {
					this.$router.replace({ query: {} });
				}
			}
		},
		language() {
			this.loaded = false;
			this.getOfficialDocument();
		},
	},
	mounted() {
		window.addEventListener('scroll', this.handleScroll);
		window.addEventListener('scroll', this.updateTocStyle);
		window.addEventListener('scroll', this.handleScroll);
		window.addEventListener('scroll', this.adjustTocHeader);
		if (this.adjustContent) {
			this.tocStyle = { ...this.tocStyle, top: '195px' };
		}
		this.getOfficialDocument();
	},
	beforeUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
		window.removeEventListener('scroll', this.updateTocStyle);
		window.removeEventListener('scroll', this.handleScroll);
		window.removeEventListener('scroll', this.adjustTocHeader);
	},
	methods: {
		getOfficialDocument() {
			const document = this.$route.path.split('/')[1];
			this.distributorInformation.getOfficialDocument(document).then((response) => {
				this.loaded = true;
				this.activeSection = response[0].id.toString();
			}).catch(() => {});
		},
		handleScroll() {
			try {
				const sections = document.querySelectorAll('.section');
				const scrollTop = window.pageYOffset;

				sections.forEach((section) => {
					if (
						scrollTop >= section.offsetTop - 100
						&& scrollTop < section.offsetTop + section.offsetHeight - 100
					) {
						this.activeSection = section.id.toString();
					}
				});

				this.$nextTick(() => {
					this.scrollActiveTocItemIntoView();
				});
			} catch {
				// Do nothing when isSmallView
			}
		},
		scrollToSection(id) {
			const section = document.getElementById(id);
			window.scrollTo({ top: section.offsetTop - 50, behavior: 'smooth' });
			this.activeSection = id.toString();
		},
		updateTocStyle() {
			try {
				const agreementsContainer = document.querySelector('.agreements-container');
				const containerRect = agreementsContainer.getBoundingClientRect();
				const toc = document.querySelector('.toc');
				const tocRect = toc.getBoundingClientRect();
				const topSpacing = 0;

				let maxHeight = containerRect.bottom - tocRect.top - topSpacing;
				maxHeight = Math.max(Math.min(maxHeight, window.innerHeight - topSpacing), 0);

				this.tocStyle = { 'max-height': `${maxHeight}px` };
			} catch {
				// Do nothing when isSmallView
			}
		},
		scrollActiveTocItemIntoView() {
			const toc = document.querySelector('.toc');
			const activeItem = document.querySelector('.toc .active-section');

			if (activeItem) {
				const itemTop = activeItem.offsetTop;
				const itemHeight = activeItem.offsetHeight;
				const tocScrollTop = toc.scrollTop;
				const tocHeight = toc.offsetHeight;

				if (itemTop < tocScrollTop) {
					toc.scrollTop = itemTop;
				} else if (itemTop + itemHeight > tocScrollTop + tocHeight) {
					toc.scrollTop = itemTop + itemHeight - tocHeight;
				}
			}
		},
		openTerms() {
			window.open(this.agreementsUrl, '_blank');
		},
		adjustTocHeader() {
			if (this.adjustContent) {
				const tocHeader = document.querySelector('.toc-header');
				if (window.scrollY > 10) {
					tocHeader.style.top = '100px';
				} else {
					tocHeader.style.top = '160px';
					this.tocStyle = { ...this.tocStyle, top: '195px' };
				}
			}
		},
	},
};
</script>

<style scoped>
.agreements-container {
	display: flex;
	overflow-y: hidden;
}
.background-image {
	position: fixed;
	max-height: 60%;
	top: 30%;
	z-index: -1; /* Position the image behind the content */
	opacity: 0.15;
}
.background-image-small {
	max-height: 30% !important;
	top: 40% !important;
}
.section {
	width: 100%;
}
.toc {
	max-width: 25%;
	flex-shrink: 0;
	overflow-y: auto;
	max-height: calc(100vh - 120px); /* Substract the height of the header and footer */
	position: fixed;
	top: calc(115px + 1.5em);
	bottom: 50px;
}
.toc-header {
	position: fixed;
	z-index: 10; /* Set the z-index higher than .toc to ensure it stays on top */
}
.toc-small {
	position: fixed;
	top: 85px;
	max-width: 90%;
	z-index: 1000;
}
.agreements-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-right: 1%;
	width: 70%;
	margin-left: 30%;
	position: relative;
}
.agreements-content-small {
	margin-right: 5%;
	width: 90%;
	margin-left: 5%;
	top: 50px;
}
.toc-item {
	display: block;
	text-decoration: none;
	padding-bottom: 5px;
}
.active-section {
	font-weight: bolder;
	background-color: #DF703D;
	color: white;
	border-radius: 3px;
	padding: 3px;
}
.p-style {
	color: #777474;
	text-align: justify;
	margin-bottom: 20px;
}
.title-1 {
	font-weight: bolder;
	font-size: 24px;
	color: #DF703D;
	text-align: center;
	margin-top: 25px;
	margin-bottom: 15px;
}
.title-2 {
	font-weight: bolder;
	font-size: 20px;
	color: #DF703D;
	text-align: left;
	margin-top: 20px;
	margin-bottom: 5px;
}
.title-3 {
	font-weight: bolder;
	font-size: 18px;
	text-align: left;
	margin-bottom: 5px;
	margin-top: 0;
}
.title-4 {
	font-weight: bolder;
	font-size: 16px;
	text-align: left;
	margin-bottom: 5px;
	margin-top: 0;
}
.loading-container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding-top: 100px;
}
</style>
