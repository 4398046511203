var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 cart"},[_c('div',{class:_vm.isSmallDevice ? 'h-80' : 'h-55'}),_c('b-card-group',[(!_vm.loading)?_c('b-card',{staticClass:"border-0 bg-white mb-0",class:_vm.isSmallDevice ? 'py-5' : 'px-5 py-4',staticStyle:{"opacity":"0.9"}},[_c('div',{staticClass:"agreements-container"},[(!_vm.isSmallDevice)?_c('div',[_c('div',{staticClass:"toc-header",style:({
							top: _vm.isReplicated ? '160px' : '100px',
						})},[_c('h4',[_c('b',[_vm._v(_vm._s(_vm.translate('table_of_contents')))])])]),_c('div',{staticClass:"toc",style:(_vm.tocStyle)},_vm._l((_vm.sections),function(section){return _c('a',{key:section.id,staticClass:"toc-item",class:{
								'active-section': _vm.activeSection === section.id.toString()
							},style:({
								'padding-left': 15 * section.attributes.level + 'px',
								'font-weight': section.attributes.level === 1 ? 'bolder' : 'normal',
							}),attrs:{"href":("#" + (section.id))},domProps:{"innerHTML":_vm._s(section.attributes.title)},on:{"click":function($event){$event.preventDefault();return _vm.scrollToSection(section.id)}}})}),0)]):_c('div',{staticClass:"toc-small"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.activeSection),expression:"activeSection"}],staticClass:"form-control",attrs:{"id":"section-item","name":"section-item"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.activeSection=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.scrollToSection(_vm.activeSection)}]}},[[(_vm.sections.length)?_vm._l((_vm.sections),function(section){return _c('option',{key:section.id,attrs:{"href":("#" + (section.id))},domProps:{"value":section.id,"innerHTML":_vm._s(section.attributes.title)}})}):_vm._e()]],2)]),_c('div',{staticClass:"agreements-content",class:{
						'agreements-content-small' : _vm.isSmallDevice,
					}},[_c('img',{staticClass:"background-image",class:{
							'background-image-small' : _vm.isSmallDevice,
						},attrs:{"src":_vm.APP_SYMBOL,"alt":"Velovita Symbol"}}),_c('div',{staticClass:"text-center",class:_vm.adjustContent ? 'mt-5 pt-2' : ''},[_c('h1',[_c('b',[_vm._v(_vm._s(_vm.translate((_vm.documentCodeName + "_title_1")).toUpperCase())+" "),_c('b',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.translate((_vm.documentCodeName + "_title_2")).toUpperCase()))])])])]),_vm._l((_vm.sections),function(section){return _c('div',{key:section.id,staticClass:"section row-12",style:({
							'padding-left': 15 * (section.attributes.level - 1) + 'px',
						}),attrs:{"id":section.id}},[_c('p',{class:'title-' + section.attributes.level,domProps:{"innerHTML":_vm._s(section.attributes.title)}}),(section.attributes.content !== null)?_c('div',{staticClass:"p-style",domProps:{"innerHTML":_vm._s(section.attributes.content)}}):_vm._e()])}),(_vm.version)?_c('div',{staticClass:"text-center text-medium primary-color pb-2"},[_vm._v(" "+_vm._s(_vm.translate('version'))+" "+_vm._s(_vm.version)+" ")]):_vm._e(),_c('div',{staticClass:"align-items-center text-center",class:_vm.isSmallDevice ? 'pb-5' : ''},[_c('button',{staticClass:"btn btn-primary w-100",attrs:{"type":"submit"},on:{"click":function($event){return _vm.openTerms()}}},[_vm._v(" "+_vm._s(_vm.translate('download'))+" ")])])],2)])]):_c('div',{staticClass:"loading-container"},[_c('is-loading',{attrs:{"size":"xl","loading-label":_vm.translate('loading'),"loading":_vm.loading}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }